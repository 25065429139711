import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { RegisterMergeText } from '../RegistrationMergeFields';
import { useDispatch, useSelector } from 'react-redux';
import { clearRegistrationData } from '../../../features/registrationSlice';
import { useRouter } from 'next/router';
import { RootState } from '../../../store/store';
import { post } from '../../../lib/utils/axiosFetcher';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import StThankYouItemWrapper from './ThankYou.style';
import UpdateEmail from '../../shared/UpdateEmail/UpdateEmail';
import { EMAIL_TEMPLATES } from '../../../constants';
import { fetchProfileDetailsForCR } from '../../../lib/utils/axiosCDPFetcher';
import { setProfileData } from '../../../features/crProfileSlice';
import { PATIENT_TYPE_ADULT } from '../../../constants';
import { sendWelcomeSms } from '../../../components/shared/Sms/helper';

interface IThankYouFields {
  changeEmailBtnText: Field<string>;
  image: ImageField;
  resendBtnText: Field<string>;
  resendText: Field<string>;
  subTitle: Field<string>;
  thankYouText: Field<string>;
  title: Field<string>;
  updatePopUpEmailLabel: Field<string>;
  updatePopUpSubTitle: Field<string>;
  updatePopUpTitle: Field<string>;
  confirmButtonText: Field<string>;
  cancelButtonnText: Field<string>;
  emailInvalidErrorMessage: Field<string>;
  emailAlreadyExistErrorMessage: Field<string>;
}
interface IThankYou {
  fields: IThankYouFields;
}

function removeTrailingSlash(url: string) {
  if (url.endsWith('/')) {
    return url.slice(0, -1);
  }
  return url;
}

const LoaderBackdrop = (props: { open: boolean }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const ThankYou: React.FC<IThankYou> = (props) => {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const dispatch = useDispatch();
  const router = useRouter();
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const [isLoading, setIsLoading] = useState(true);
  const isSmsConsented = useSelector(
    (state: RootState) => state?.registrationDetails?.contactPreference?.SMS
  );
  const isMobile = useSelector(
    (state: RootState) => state?.registrationDetails?.contactPreference?.isMobile
  );
  const mobileNumber = useSelector(
    (state: RootState) => state?.registrationDetails?.contactPreference?.contactNumber
  );
  const registrationDetails = useSelector((state: RootState) => state?.registrationDetails);
  const resendCounterRef = useRef(0);

  // enum for emailType
  // Registration-Welcome
  // Manage-Profile

  const callDapApiSendEmailAllDetails = async (registrationDetails: any) => {
    try {
      const mailingListId = siteSettings?.sendMailingListId?.value || '';
      const emailType = EMAIL_TEMPLATES.REGISTRATION_WELCOME;
      const baseUrl = siteSettings?.baseUrl?.value || '';
      const userConditions = registrationDetails?.selectedCOIList?.map(
        (condition) => condition.key
      );
      const therapeuticArea = userConditions ? userConditions?.join(', ') : '';
      const relevantItemsList = registrationDetails?.patientDetails?.map(
        (arrayIndex) => arrayIndex.index + 1
      );
      const selectedRelevantItems = relevantItemsList ? relevantItemsList?.join(', ') : '';
      const cleanBaseUrl = removeTrailingSlash(baseUrl);
      const myProfileEndpoint = siteSettings?.myProfileItemUrl?.value?.href || '';
      const myProfileUrl = `${cleanBaseUrl}${myProfileEndpoint}`;
      const payload = {
        mailingListId,
        source: 'CR',
        baseUrl,
        myProfileUrl: myProfileUrl,
        communityPageLink: '',
        resendAttemptCount: resendCounterRef.current,
        subscriber: {
          emailAddress: registrationDetails?.registration?.email,
          name: `${registrationDetails?.aboutYourSelf?.firstName || ''} ${
            registrationDetails?.aboutYourSelf?.lastName || ''
          }`,
          firstName: registrationDetails?.aboutYourSelf?.firstName || '',
          lastName: registrationDetails?.aboutYourSelf?.lastName || '',
          phone: registrationDetails?.contactPreference?.contactNumber || '',
          dateOfBirth: registrationDetails?.dob?.dob || '',
          gender: registrationDetails?.dob?.gender || '',
          isEmailOptedIn: registrationDetails?.contactPreference?.Email || false,
          isSmsOptedIn: registrationDetails?.contactPreference?.SMS || false,
          isSecondaryConsentAccepted: registrationDetails?.contactPreference?.agree || false,
          preferredLanguage: sitecoreContext?.language as any,
          therapeuticArea,
          selectedRelevantItems,
          interestedInAtHomeTrials: registrationDetails?.locationDetails?.homeTrial
            ? registrationDetails?.locationDetails?.homeTrial?.toLowerCase() === 'yes'
            : false,
          myProfileLink: '',
          customUnsubscribeLink: '',
          crWelcomeEmailType: emailType,
          registrationDateTime: new Date().toISOString(),
          contactType: PATIENT_TYPE_ADULT,
          zipCode: registrationDetails?.locationDetails?.zipcode || '',
        },
        enrollmentId: '',
        siteSettingsId: sitecoreContext?.siteSettingsId as any,
        language: sitecoreContext?.language as any,
        isCrEmailCheck: false,
      };
      await post('/Send/AddContactsToCRMailingListWithAllDetail', payload);
    } catch (_error) {}
  };

  const callSendSmsEvent = async () => {
    try {
      const isSendSmsEnabled = siteSettings?.enableSmsSendFeature?.value;
      if (isSendSmsEnabled && isSmsConsented && isMobile) {
        const transactionalFromNumber = siteSettings?.transactionalFromNumber?.value;
        const pinPointProjectId = siteSettings?.pinPointProjectId?.value;
        const smsStudyName = siteSettings?.smsStudyName?.value;
        const smsSponsor = siteSettings?.smsSponsor?.value;
        const sourceIdentifier = 'CR';
        const countryCode = siteSettings?.countryCode?.value;
        const smsRootPath = sitecoreContext?.smsRootPath;
        const smsExtensionData = {
          enrollmentId: '',
          smsType: 'Cr-Registration',
          transactionalFromNumber: transactionalFromNumber || '',
          pinPointProjectId: pinPointProjectId || '',
          smsStudyName: smsStudyName || '',
          smsSponsor: smsSponsor || '',
          sourceIdentifier: sourceIdentifier || '',
          countryCode: countryCode || '',
          smsRootPath: smsRootPath || '',
          mobileNumber: `+1${mobileNumber}` || '',
        };
        sendWelcomeSms(smsExtensionData);
      }
    } catch (_error) {}
  };

  useEffect(() => {
    // This function will run when the page changes
    const handleRouteChange = () => {
      dispatch(clearRegistrationData());
    };

    // Set up a listener for route changes
    router.events.on('routeChangeStart', handleRouteChange);
    // Clean up the listener when the component unmounts
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [dispatch, router]);

  useEffect(() => {
    callDapApiSendEmailAllDetails(registrationDetails);
    callSendSmsEvent();
    const initialCall = async () => {
      const myProfileVerificationToken = sessionStorage.getItem('myProfileVerificationToken');
      if (myProfileVerificationToken) {
        try {
          const { data } = await fetchProfileDetailsForCR(myProfileVerificationToken);
          if (data) {
            dispatch(setProfileData(data));
          }
        } catch (_e) {}
      }
    };
    setTimeout(() => {
      initialCall();
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <Box>
      <LoaderBackdrop open={isLoading} />
      <Grid container justifyContent="center">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography className="title" align="center" gutterBottom>
              <RegisterMergeText field={props.fields.title} />
            </Typography>

            <Typography className="subtitle" variant="subtitle1" align="center">
              <RegisterMergeText field={props.fields.subTitle} />
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <StThankYouItemWrapper className="mail-box" xs={12}>
            <UpdateEmail {...props} />
          </StThankYouItemWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ThankYou;
